define('nypr-django-for-ember/utils/alien-dom', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isInDom = isInDom;
  exports.clearAlienDom = clearAlienDom;
  exports.addAlienLanding = addAlienLanding;
  exports.assign = assign;

  // The Alien DOM is a DOM that exists beyond the reaches of an Ember app's
  // understanding, i.e. an HTML document that is already present when the app boots.

  // BEGIN-SNIPPET is-in-dom
  // When we are operating in progressive boot mode, Ember can detect if a requested
  // django-page model is already present by testing the requested id (the url path)
  // against a marker provided by django.
  function isInDom(id) {
    var unrenderedMarker = document.querySelector('[type="text/x-wnyc-marker"]');
    return unrenderedMarker && id === unrenderedMarker.getAttribute('data-url');
  }
  // END-SNIPPET

  // When we have a django-page model ready to load, we need to clean out any remnants
  // of an Alien DOM. This will run on every django-page render, but should be a simple
  // no-op after one run.
  function clearAlienDom() {
    var toRemove = _emberGetConfig.default.alienDom.toRemove;
    if (!toRemove) {
      var root = _emberGetConfig.default.environment === 'test' ? '#ember-testing' : 'body';
      toRemove = root + ' > :not(.ember-view):not(#fb-root), ' + root + ' > head > link[rel=stylesheet]:not([href*=assets])';
    }
    var nodesToRemove = document.querySelectorAll(_emberGetConfig.default.alienDom.toRemove);

    Array.from(nodesToRemove).forEach(function (n) {
      n.parentNode.removeChild(n);
    });
  }

  function addAlienLanding(id, coordinates) {
    var landingSite = document.querySelector(coordinates);
    var lander = document.createElement('div');
    lander.id = id;
    try {
      if (Ember.testing) {
        landingSite.appendChild(lander);
      } else {
        landingSite.parentNode.insertBefore(lander, landingSite);
      }
    } catch (e) {
      return false;
    }
  }

  // this method could be a one line, but in testing it would open a new window,
  // so let's us override in testing with a method of our own, located at
  // `window.assign`.
  function assign(url) {
    if (Ember.testing) {
      window.assign(url);
    } else {
      window.location.assign(url);
    }
  }
});