define('ember-burger-menu/components/bm-menu-item', ['exports', 'ember-burger-menu/templates/components/bm-menu-item', 'ember-burger-menu/computed/style-for', 'ember-burger-menu/utils/is-fastboot', 'closest'], function (exports, _bmMenuItem, _styleFor, _isFastboot, _closest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bmMenuItem.default,
    classNames: ['bm-menu-item'],
    attributeBindings: ['style'],

    state: null,

    menuItems: null,
    dismissOnClick: false,
    style: (0, _styleFor.default)('menuItem').readOnly(),

    index: Ember.computed('menuItems.[]', function () {
      if ((0, _isFastboot.default)()) {
        return -1;
      }

      var position = -1;
      var item = this.element;

      if (item) {
        var menu = (0, _closest.default)(item, '.bm-menu', true);
        if (menu) {
          position = [].slice.call(menu.querySelectorAll('.bm-menu-item')).indexOf(item);
        }
      }

      return position;
    }).readOnly(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this.get('menuItems'), 'addObject', this.get('elementId'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this.get('menuItems'), 'removeObject', this.get('elementId'));
    },
    click: function click() {
      this._super.apply(this, arguments);

      if (this.get('dismissOnClick')) {
        this.get('state.actions').close();
      }
    }
  });
});