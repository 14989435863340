define('nypr-ui/components/nypr-ui/brand-header', ['exports', 'nypr-ui/templates/components/nypr-ui/brand-header'], function (exports, _brandHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _brandHeader.default,
    tagName: '',
    init: function init() {
      var menuItems = [{
        name: 'WNYC',
        slug: 'wnyc',
        url: 'https://www.wnyc.org?utm_medium=partnersite&utm_campaign=brandheader&utm_source=' + this.get('siteSlug'),
        svg: 'wnyc-logo'
      }, {
        name: 'Gothamist',
        slug: 'gothamist',
        url: 'https://www.gothamist.com?utm_medium=partnersite&utm_campaign=brandheader&utm_source=' + this.get('siteSlug'),
        svg: 'gothamist-logo'
      }, {
        name: 'WNYC Studios',
        slug: 'wnycstudios',
        url: 'https://www.wnycstudios.org?utm_medium=partnersite&utm_campaign=brandheader&utm_source=' + this.get('siteSlug'),
        svg: 'wnyc-studios-logo'
      }, {
        name: 'NJPR',
        slug: 'njpr',
        url: 'http://www.njpublicradio.org?utm_medium=partnersite&utm_campaign=brandheader&utm_source=' + this.get('siteSlug'),
        svg: 'njpr-logo'
      }, {
        name: 'WQXR',
        slug: 'wqxr',
        url: 'https://www.wqxr.org?utm_medium=partnersite&utm_campaign=brandheader&utm_source=' + this.get('siteSlug'),
        svg: 'wqxr-logo'
      }, {
        name: 'New Sounds',
        slug: 'newsounds',
        url: 'https://www.newsounds.org?utm_medium=partnersite&utm_campaign=brandheader&utm_source=' + this.get('siteSlug'),
        svg: 'new-sounds-logo'
      }, {
        name: 'The Greene Space',
        slug: 'thegreenespace',
        url: 'https://www.thegreenespace.org?utm_medium=partnersite&utm_campaign=brandheader&utm_source=' + this.get('siteSlug'),
        svg: 'greene-space-logo'
      }];
      this.set('menuItems', menuItems);

      this._super.apply(this, arguments);
    },

    siteSlug: Ember.computed(function () {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.siteSlug;
    })
  });
});