define('nypr-publisher-lib/components/story-comments', ['exports', 'ember-get-config', 'nypr-publisher-lib/templates/components/story-comments'], function (exports, _emberGetConfig, _storyComments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _storyComments.default,
    classNames: ['story-comments'],
    adminURL: _emberGetConfig.default.adminRoot + '/admin',

    comments: Ember.computed('getComments', {
      get: function get() {
        var _this = this;

        this.set('isLoading', true);
        Ember.get(this, 'getComments')().then(function (comments) {
          _this.set('comments', comments);
        }).finally(function () {
          return _this.set('isLoading', false);
        });
      },
      set: function set(k, v) {
        return v;
      }
    }),

    didInsertElement: function didInsertElement() {
      if (Ember.get(this, 'isShowingComments')) {
        Ember.get(this, 'element').scrollIntoView();
      }
    },


    commentsCount: Ember.computed('comments.[]', 'story', function () {
      if (Ember.get(this, 'comments')) {
        return Ember.get(this, 'comments.length');
      } else {
        return Ember.get(this, 'story.commentsCount');
      }
    }),

    isShowingForm: Ember.computed({
      get: function get() {
        return Ember.get(this, 'isShowingComments');
      },
      set: function set(k, v) {
        return v;
      }
    }),

    isShowingComments: Ember.computed({
      get: function get() {
        var hash = window.location.hash.slice(1).split('&');
        return hash.indexOf('comments') !== -1 || hash.indexOf('commentlist') !== -1;
      },
      set: function set(k, v) {
        return v;
      }
    }),

    actions: {
      getComments: function getComments() {
        this.set('isShowingComments', true);
        this.set('isShowingForm', true);
      },
      saveSuccess: function saveSuccess() {
        this.set('isShowingForm', false);
        this.set('isSuccess', true);
      }
    }
  });
});