define('nypr-publisher-lib/models/stream', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // BEGIN-SNIPPET stream-fields
    audioType: 'livestream',

    alwaysBroadcasting: (0, _attr.default)('boolean'),
    hasPlaylists: (0, _attr.default)('boolean'),
    imageLogo: (0, _attr.default)('string'),
    scheduleUrl: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    shortDescription: (0, _attr.default)('string'),
    slug: (0, _attr.default)('string'),
    whatsOn: (0, _attr.default)('number'),
    position: (0, _attr.default)('number'),
    playlistUrl: (0, _attr.default)('string'),
    cmsPK: (0, _attr.default)('number'),
    twitterHandle: (0, _attr.default)('string'),
    sourceTags: (0, _attr.default)('string', { defaultValue: '' }),
    sitePriority: (0, _attr.default)('number'),
    currentShow: (0, _attr.default)(),
    currentPlaylistItem: (0, _attr.default)(),
    future: (0, _attr.default)(),
    urls: (0, _attr.default)(),

    currentStory: (0, _relationships.belongsTo)('story'),
    playlist: (0, _relationships.belongsTo)('playlist'),

    story: Ember.computed.readOnly('currentStory'),
    audioBumper: (0, _attr.default)('string'),

    isWQXR: Ember.computed('sourceTags', function () {
      return Ember.get(this, 'sourceTags').includes('wqxr_site');
    }),

    isWNYC: Ember.computed('sourceTags', function () {
      return Ember.get(this, 'sourceTags').includes('wnyc_site');
    }),

    liveWQXR: Ember.computed('isWQXR', 'whatsOn', function () {
      return Ember.get(this, 'isWQXR') && Ember.get(this, 'whatsOn') > 0;
    }),

    liveWNYC: Ember.computed('isWNYC', 'whatsOn', 'alwaysBroadcasting', function () {
      return Ember.get(this, 'isWNYC') && (Ember.get(this, 'whatsOn') > 0 || Ember.get(this, 'alwaysBroadcasting'));
    }),

    currentComposer: Ember.computed('currentPlaylistItem', function () {
      return Ember.get(this, 'currentPlaylistItem.catalogEntry.composer');
    }),
    currentPiece: Ember.computed('currentPlaylistItem', function () {
      return Ember.get(this, 'currentPlaylistItem.catalogEntry.title');
    }),

    forListenAction: function forListenAction() {
      var _this = this;

      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return this.get('currentStory').then(function (s) {
        data.current_audio_position = 0; // stream should always report 0
        return Object.assign({
          audio_type: 'livestream',
          cms_id: s && s.get('cmsPK'),
          item_type: s && s.get('itemType'), // episode, article, segment
          stream_id: _this.get('cmsPK')
        }, data);
      });
    }
    // END-SNIPPET

  });
});