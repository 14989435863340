define('nypr-publisher-lib/adapters/comment', ['exports', 'ember-get-config', 'ember-data', 'nypr-django-for-ember/utils/wrap-ajax', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-fetch/mixins/adapter-fetch'], function (exports, _emberGetConfig, _emberData, _wrapAjax, _dataAdapterMixin, _adapterFetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_adapterFetch.default, _dataAdapterMixin.default, {
    host: _emberGetConfig.default.publisherAPI,
    namespace: 'v1/list/comments',
    authorize: function authorize(xhr) {
      var headers = this.get('session').authorize({});
      for (var h in headers) {
        xhr.setRequestHeader(h, headers[h]);
      }
    },
    query: function query(store, type, _query) {
      var url = [this.host, this.namespace, _query.itemTypeId, _query.itemId, ''].join('/');
      var options = this.ajaxOptions(url, 'GET', {});
      return (0, _wrapAjax.default)(options);
    }
  });
});