define("ember-burger-menu/utils/element-closest", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = closest;
  function closest(targetElement, selector) {
    if (targetElement.closest) {
      return targetElement.closest(selector);
    }

    var matchesFn = targetElement.matches;
    if (!matchesFn) {
      matchesFn = targetElement.msMatchesSelector || targetElement.webkitMatchesSelector;
    }

    if (!document.documentElement.contains(targetElement)) {
      return null;
    }

    var el = targetElement;

    do {
      if (matchesFn(selector)) {
        return el;
      }
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);

    return null;
  }
});