define('ember-lifeline/run-task', ['exports', 'ember-lifeline/utils/get-task', 'ember-lifeline/utils/disposable'], function (exports, _getTask, _disposable) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports._setRegisteredTimers = _setRegisteredTimers;
    exports.runTask = runTask;
    exports.scheduleTask = scheduleTask;
    exports.throttleTask = throttleTask;
    exports.cancelTask = cancelTask;

    /**
     * A map of instances/timers that allows us to
     * store cancelIds for scheduled timers per instance.
     *
     * @private
     *
     */
    var registeredTimers = new WeakMap();
    /**
     * Test use only. Allows for swapping out the WeakMap to a Map, giving
     * us the ability to detect whether the timers set is empty.
     *
     * @private
     * @param {*} mapForTesting A map used to ensure correctness when testing.
     */
    function _setRegisteredTimers(mapForTesting) {
        registeredTimers = mapForTesting;
    }
    /**
       Registers and runs the provided task function for the provided object at the specified
       timeout (defaulting to 0). The timer is properly canceled if the object is destroyed
       before it is invoked.
    
       Example:
    
       ```js
       import Component from 'ember-component';
       import { runTask, runDisposables } from 'ember-lifeline';
    
       export default Component.extend({
         didInsertElement() {
           runTask(this, () => {
             console.log('This runs after 5 seconds if this component is still displayed');
           }, 5000)
         },
    
         willDestroy() {
           this._super(...arguments);
           runDisposables(this);
         }
       });
       ```
    
       @function runTask
       @param { Object } obj the instance to register the task for
       @param { Function | String } taskOrName a function representing the task, or string
                                               specifying a property representing the task,
                                               which is run at the provided time specified
                                               by timeout
       @param { Number } [timeout=0] the time in the future to run the task
       @public
       */
    function runTask(obj, taskOrName) {
        var timeout = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
        (false && !(!obj.isDestroyed) && Ember.assert('Called `runTask` on destroyed object: ' + obj + '.', !obj.isDestroyed));

        var task = (0, _getTask.default)(obj, taskOrName, 'runTask');
        var timers = getTimers(obj);
        var cancelId = Ember.run.later(function () {
            timers.delete(cancelId);
            task.call(obj);
        }, timeout);
        timers.add(cancelId);
        return cancelId;
    }
    /**
       Adds the provided function to the named queue for the provided object. The timer is
       properly canceled if the object is destroyed before it is invoked.
    
       Example:
    
       ```js
       import Component from 'ember-component';
       import { scheduleTask, runDisposables } from 'ember-lifeline';
    
       export default Component.extend({
         init() {
           this._super(...arguments);
           scheduleTask(this, 'actions', () => {
             console.log('This runs at the end of the run loop (via the actions queue) if this component is still displayed');
           })
         },
    
         willDestroy() {
           this._super(...arguments);
           runDisposables(this);
         }
       });
       ```
    
       @function scheduleTask
       @param { Object } obj the instance to register the task for
       @param { String } queueName the queue to schedule the task into
       @param { Function | String } taskOrName a function representing the task, or string
                                               specifying a property representing the task,
                                               which is run at the provided time specified
                                               by timeout
       @param { ...* } args arguments to pass to the task
       @public
       */
    function scheduleTask(obj, queueName, taskOrName) {
        (false && !(typeof queueName === 'string') && Ember.assert('Called `scheduleTask` without a string as the first argument on ' + obj + '.', typeof queueName === 'string'));
        (false && !(queueName !== 'afterRender') && Ember.assert('Called `scheduleTask` while trying to schedule to the `afterRender` queue on ' + obj + '.', queueName !== 'afterRender'));
        (false && !(!obj.isDestroyed) && Ember.assert('Called `scheduleTask` on destroyed object: ' + obj + '.', !obj.isDestroyed));

        var task = (0, _getTask.default)(obj, taskOrName, 'scheduleTask');
        var timers = getTimers(obj);
        var cancelId = void 0;
        var taskWrapper = function taskWrapper() {
            for (var _len2 = arguments.length, taskArgs = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
                taskArgs[_key2] = arguments[_key2];
            }

            timers.delete(cancelId);
            task.call.apply(task, [obj].concat(taskArgs));
        };

        for (var _len = arguments.length, args = Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
            args[_key - 3] = arguments[_key];
        }

        cancelId = Ember.run.schedule.apply(undefined, [queueName, obj, taskWrapper].concat(args));
        timers.add(cancelId);
        return cancelId;
    }
    /**
       Runs the function with the provided name immediately, and only once in the time window
       specified by the timeout argument.
    
       Example:
    
       ```js
       import Component from 'ember-component';
       import { throttleTask, runDisposables } from 'ember-lifeline';
    
       export default Component.extend({
         logMe() {
           console.log('This will run once immediately, then only once every 300ms.');
         },
    
         click() {
           throttleTask(this, 'logMe', 300);
         },
    
         destroy() {
           runDisposables(this);
         }
       });
       ```
    
       @method throttleTask
       @param { Object } obj the instance to register the task for
       @param { String } taskName the name of the task to throttle
       @param { Number } [timeout] the time in the future to run the task
       @public
       */
    function throttleTask(obj, taskName) {
        var timeout = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
        (false && !(typeof taskName === 'string') && Ember.assert('Called `throttleTask` without a string as the first argument on ' + obj + '.', typeof taskName === 'string'));
        (false && !(typeof obj[taskName] === 'function') && Ember.assert('Called `throttleTask(\'' + taskName + '\', ' + timeout + ')` where \'' + taskName + '\' is not a function.', typeof obj[taskName] === 'function'));
        (false && !(!obj.isDestroyed) && Ember.assert('Called `throttleTask` on destroyed object: ' + obj + '.', !obj.isDestroyed));

        var timers = getTimers(obj);
        var cancelId = Ember.run.throttle(obj, taskName, timeout);
        timers.add(cancelId);
        return cancelId;
    }
    function cancelTask(obj, cancelId) {
        if (typeof cancelId === 'undefined') {
            Ember.deprecate('ember-lifeline cancelTask called without an object. New syntax is cancelTask(obj, cancelId) and avoids a memory leak.', true, {
                id: 'ember-lifeline-cancel-task-without-object',
                until: '4.0.0'
            });
            cancelId = obj;
        } else {
            var timers = getTimers(obj);
            timers.delete(cancelId);
        }
        Ember.run.cancel(cancelId);
    }
    function getTimersDisposable(obj, timers) {
        return function () {
            timers.forEach(function (cancelId) {
                cancelTask(obj, cancelId);
            });
            timers.clear();
        };
    }
    function getTimers(obj) {
        var timers = registeredTimers.get(obj);
        if (!timers) {
            timers = new Set();
            registeredTimers.set(obj, timers);
            (0, _disposable.registerDisposable)(obj, getTimersDisposable(obj, timers));
        }
        return timers;
    }
});