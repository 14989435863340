define('nypr-audio-services/components/nypr-player-integration', ['exports', 'nypr-audio-services/helpers/song-metadata', 'nypr-audio-services/templates/components/nypr-player-integration'], function (exports, _songMetadata, _nyprPlayerIntegration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _nyprPlayerIntegration.default,

    hifi: Ember.inject.service(),
    dj: Ember.inject.service(),
    listenAnalytics: Ember.inject.service(),

    /* To determine whether or not to reveal the notification bar. The messaging
      is handled by the autoplay-message component */
    bumper: Ember.inject.service('bumper-state'),
    revealNotificationBar: Ember.computed.and('didNotDismiss', 'bumper.revealNotificationBar'),
    didDimiss: false,
    didNotDismiss: Ember.computed.not('didDismiss'),

    currentSound: Ember.computed.reads('dj.currentSound'),
    currentAudio: Ember.computed.reads('dj.currentContentModel'),
    currentAudioType: Ember.computed.reads('dj.currentContentType'),
    currentAudioId: Ember.computed.reads('dj.currentContentId'),

    currentTitle: Ember.computed.or('currentAudio.title', '_currentTitleFromShow'),
    _currentTitleFromShow: Ember.computed('currentAudio', function () {
      return this.get('currentAudio.currentShow.showTitle') + ' on ' + this.get('currentAudio.name');
    }),

    story: Ember.computed.or('currentAudio.currentStory', 'currentAudio'),
    storyTitle: Ember.computed.or('currentAudio.title', 'currentAudio.currentShow.episodeTitle'),
    storyUrl: Ember.computed.or('currentAudio.url', 'currentAudio.currentShow.episodeUrl'),

    show: Ember.computed.reads('currentAudio.headers.brand'),
    showTitle: Ember.computed.or('show.title', 'currentAudio.currentShow.showTitle'),
    showUrl: Ember.computed.or('show.url', 'currentAudio.currentShow.showUrl'),

    catalogEntry: Ember.computed.reads('currentAudio.currentPlaylistItem.catalogEntry'),
    songDetails: Ember.computed('catalogEntry', function () {
      if (this.get('catalogEntry')) {
        return (0, _songMetadata.songMetadata)([Ember.get(this, 'catalogEntry')]);
      }
    }),

    isStream: Ember.computed.equal('currentAudio.audioType', 'livestream'),
    streamName: Ember.computed.reads('currentAudio.name'),
    streamScheduleUrl: Ember.computed.reads('currentAudio.scheduleUrl'),
    streamPlaylistUrl: Ember.computed('currentAudio.playlistUrl', function () {
      if (Ember.get(this, 'currentAudio.playlistUrl')) {
        return '/streams/' + Ember.get(this, 'currentAudio.id');
      }
    }),

    autofocus: true,

    image: Ember.computed.reads('currentAudio.imageMain.url'),
    fallbackImage: Ember.computed.reads('currentAudio.headers.brand.logoImage.url'),
    defaultImageUrl: '/assets/img/bg/player-background.png',
    backdropImageUrl: Ember.computed.or('image', 'fallbackImage', 'defaultImageUrl'),

    playingAudioType: 'on_demand', //bumper, livestream, on_demand

    queueLength: 0,
    showQueue: false,

    actions: {
      onPlay: function onPlay() {
        // handled by listen analytics
      },
      onPause: function onPause() {
        // handled by listen analytics
      },
      onFastForward: function onFastForward() {
        // handled by listen analytics
      },
      onRewind: function onRewind() {
        // handled by listen analytics
      },
      onSetPosition: function onSetPosition() {
        // handled by listen analytics
      }
    }
  });
});