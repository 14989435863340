define('nypr-django-for-ember/serializers/django-page', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _emberData.default.Serializer.extend({
    // BEGIN-SNIPPET django-page-serializer
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id /*, requestType */) {
      var attributes = {};
      if (payload instanceof Document) {
        var doc = void 0;
        if (Ember.testing) {
          doc = document.implementation.createHTMLDocument();
          doc.body.appendChild(payload.querySelector('#ember-testing').cloneNode(true));
        } else {
          doc = payload.documentElement.cloneNode(true);
        }

        attributes.inlineDocument = serializeInlineDoc(doc);
      } else {
        attributes.text = payload;
      }

      return {
        data: {
          type: 'django-page',
          id: id,
          attributes: attributes
        }
      };
    }
    // END-SNIPPET

  });


  // BEGIN-SNIPPET serialize-inline-doc
  // on cold boots, the app consumes the current `document`, so we have to do
  // some clean up to make sure that things like rendered Ember components and 
  // the <link> and <script> tags for the Ember app aren't consumed as part of the
  // django-page model. If we didn't clean these out, every time this django-page
  // model was rendered, it would load a new version of the ember app within it self.
  function serializeInlineDoc(inlineDoc) {
    var toClean = [];
    toClean.push.apply(toClean, _toConsumableArray(inlineDoc.querySelectorAll('.ember-view')));
    toClean.push.apply(toClean, _toConsumableArray(inlineDoc.querySelectorAll('[data-ember-asset]')));

    toClean.forEach(function (n) {
      return n && n.parentNode.removeChild(n);
    });

    return inlineDoc;
  }
  // END-SNIPPET
});