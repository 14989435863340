define('nypr-publisher-lib/components/related-stories', ['exports', 'nypr-publisher-lib/templates/components/related-stories'], function (exports, _relatedStories) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'section',
    layout: _relatedStories.default,
    classNames: ['related-stories'],

    stories: Ember.computed('getStories', {
      get: function get() {
        var _this = this;

        Ember.get(this, 'getStories')().then(function (stories) {
          Ember.set(_this, 'stories', stories);
          // TODO: make this an app concern
          if (_this.$().imagesLoaded) {
            Ember.run.scheduleOnce('afterRender', _this, _this.imagesLoaded);
          }
        });
      },
      set: function set(k, v) {
        return v;
      }
    }),

    imagesLoaded: function imagesLoaded() {
      // here we are, promise fulfilled, DOM rendered, so let's register this
      // call back to run once all the <img/> els are finished downloading
      this.$().imagesLoaded().progress(function (instance, image) {
        Ember.$(image.img).addClass('is-loaded');
      });
    }
  });
});