define('nypr-publisher-lib/models/channel', ['exports', 'ember-data', 'nypr-publisher-lib/models/bucket-item', 'nypr-publisher-lib/helpers/normalize-for-sorting'], function (exports, _emberData, _bucketItem, _normalizeForSorting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _bucketItem.default.extend({
    // BEGIN-SNIPPET channel-fields
    altLayout: _emberData.default.attr('boolean'),
    slug: _emberData.default.attr('string'),
    cmsPK: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    siteId: _emberData.default.attr('number'),
    canonicalHost: Ember.computed('url', function () {
      var url = Ember.get(this, 'url');
      var hostMatch = url && url.match(/\/\/([^/]+)\//);
      if (hostMatch) {
        return hostMatch[1];
      }
      return undefined;
    }),
    sortableTitle: Ember.computed('title', function () {
      var title = this.getWithDefault('title', '');
      return (0, _normalizeForSorting.normalizeForSorting)([title]);
    }),
    about: _emberData.default.belongsTo('api-response', { async: false }),

    sidebarChunks: _emberData.default.attr(), // Array of custom html markup
    chunkSidebarTop: Ember.computed('sidebarChunks', {
      get: function get() {
        var chunks = Ember.get(this, 'sidebarChunks');
        if (!chunks) {
          return '';
        }
        var chunk = Ember.A(Ember.A(chunks).compact()).findBy('position', 'top');
        if (chunk) {
          var text = chunk.content.replace(/\\x3C\/script>/g, '</script>');
          return this.store.createRecord('django-page', { text: text });
        } else {
          return '';
        }
      }
    }),
    chunkSidebarBottom: Ember.computed('sidebarChunks', {
      get: function get() {
        var chunks = Ember.get(this, 'sidebarChunks');
        if (!chunks) {
          return '';
        }
        var chunk = Ember.A(Ember.A(chunks).compact()).findBy('position', 'bottom');
        if (chunk) {
          var text = chunk.content.replace(/\\x3C\/script>/g, '</script>');
          return this.store.createRecord('django-page', { text: text });
        } else {
          return '';
        }
      }
    }),
    tease: _emberData.default.attr('string'),
    teaseForDjangoPage: Ember.computed('tease', function () {
      var text = this.getWithDefault('tease', '').replace(/\\x3C\/script>/g, '</script>');
      return this.store.createRecord('django-page', { text: text });
    }),
    donateChunk: _emberData.default.attr(),
    headerDonateChunk: _emberData.default.attr('string'),

    linkroll: _emberData.default.attr(),
    donationUrl: _emberData.default.attr(),
    bgColor: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    marqueeImage: _emberData.default.attr(),
    studiosMarqueeImage: _emberData.default.attr(),
    studiosMarqueeMobileOffset: _emberData.default.attr('number'),
    podcastLinks: _emberData.default.attr(),
    rssFeed: _emberData.default.attr('string'),
    logoImage: _emberData.default.attr(),
    fbImage: _emberData.default.attr(),
    listingObjectType: _emberData.default.attr('string'),
    itemType: Ember.computed.readOnly('listingObjectType'),
    editLink: _emberData.default.attr('string'),
    socialLinks: _emberData.default.attr(),
    facebook: Ember.computed.filterBy('socialLinks', 'title', 'facebook'),
    twitter: Ember.computed.filterBy('socialLinks', 'title', 'twitter'),
    newsletter: Ember.computed.filterBy('socialLinks', 'title', 'newsletter'),
    featured: _emberData.default.belongsTo('story', { inverse: null }),
    scheduleSummary: _emberData.default.attr('string'),
    newsletterListId: _emberData.default.attr('string'),
    producingOrganizations: _emberData.default.attr(),
    // computeds
    hasLinkroll: Ember.computed.bool('linkroll.length'),
    hasMarquee: Ember.computed.bool('marqueeImage'),
    hasSubscriptionLinks: Ember.computed.bool('podcastLinks.length'),
    hasHeaderButtons: Ember.computed.or('donateChunk', 'hasSubscriptionLinks'),
    nprAnalyticsDimensions: _emberData.default.attr()
    // END-SNIPPET
  });
});