define('nypr-publisher-lib/serializers/stream', ['exports', 'ember-data', 'nypr-publisher-lib/helpers/camelize-keys'], function (exports, _emberData, _camelizeKeys) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _emberData.default.JSONAPISerializer.extend({
    keyForAttribute: function keyForAttribute(key) {
      return Ember.String.underscore(key);
    },

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, modelClass, _ref) {
      var stream = _ref.stream,
          whatsOn = _ref.whatsOn;

      var json = this._apiFormatStream(stream);
      var transformed = { data: this._attachWhatsOn(json, whatsOn) };

      for (var _len = arguments.length, rest = Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
        rest[_key - 3] = arguments[_key];
      }

      return this._super.apply(this, [store, modelClass, transformed].concat(_toConsumableArray(rest)));
    },
    normalizeFindAllResponse: function normalizeFindAllResponse(store, modelClass, _ref2) {
      var _this = this;

      var streams = _ref2.streams,
          whatsOn = _ref2.whatsOn;

      streams = streams.results.sort(function (a, b) {
        return a.id - b.id;
      });
      var data = streams.map(function (stream) {
        var json = _this._apiFormatStream(stream);
        return _this._attachWhatsOn(json, whatsOn[stream.slug]);
      });

      for (var _len2 = arguments.length, rest = Array(_len2 > 3 ? _len2 - 3 : 0), _key2 = 3; _key2 < _len2; _key2++) {
        rest[_key2 - 3] = arguments[_key2];
      }

      return this._super.apply(this, [store, modelClass, { data: data }].concat(_toConsumableArray(rest)));
    },


    // TODO: this should be handled by ember
    _apiFormatStream: function _apiFormatStream(data) {
      var keys = ['has_playlists', 'image_logo', 'name', 'slug', 'schedule_url', 'short_description', 'playlist_url', 'whats_on', 'audio_bumper', 'twitter_handle', 'source_tags', 'site_priority', 'always_broadcasting'];
      var attributes = {};
      keys.forEach(function (k) {
        return attributes[k] = data[k];
      });
      attributes.urls = this._findPreferredStreams(data);
      attributes.cms_pk = data.id;
      return {
        id: data.slug,
        type: 'stream',
        attributes: attributes
      };
    },
    _attachWhatsOn: function _attachWhatsOn(json, whatsOn) {
      if (!whatsOn) {
        return json;
      }

      var attributes = json.attributes,
          relationships = json.relationships;
      var current_show = whatsOn.current_show,
          has_playlists = whatsOn.has_playlists,
          current_playlist_item = whatsOn.current_playlist_item,
          future = whatsOn.future;


      if (current_show) {
        attributes.current_show = current_show;
        if (current_show.show_title) {
          attributes.current_show.episodeTitle = current_show.title;
          attributes.current_show.episodeUrl = current_show.url;
          attributes.current_show.showTitle = current_show.show_title;
          attributes.current_show.showUrl = current_show.show_url;
        } else {
          attributes.current_show.showTitle = current_show.title;
          attributes.current_show.showUrl = current_show.url;
          attributes.current_show.episodeTitle = null;
          attributes.current_show.episodeUrl = null;
        }
        if (current_show.episode_pk) {
          relationships = relationships || {};
          relationships['current-story'] = {
            data: {
              type: 'story',
              id: current_show.episode_pk
            }
          };
        }
        if (has_playlists) {
          relationships = relationships || {};
          relationships.playlist = {
            data: {
              type: 'playlist',
              id: json.id
            }
          };
        }
      }
      if (current_playlist_item) {
        attributes.current_playlist_item = (0, _camelizeKeys.camelizeKeys)([current_playlist_item]);
      }
      if (future) {
        attributes.future = [];
        future.forEach(function (p, i) {
          return attributes.future[i] = (0, _camelizeKeys.camelizeKeys)([p]);
        });
      }

      json.attributes = attributes;
      json.relationships = relationships;
      return json;
    },


    // BEGIN-SNIPPET stream-serializer-urls
    // given an object with a urls key, return a sorted array with stream mounts
    // in this order:
    // mobile platforms: [icecast aac, icecast mp3]
    // non-mobile (desktop) platforms: [icecast mp3, icecast aac]
    _findPreferredStreams: function _findPreferredStreams(_ref3) {
      var urls = _ref3.urls;

      if (!urls) {
        return [];
      }
      var browser = void 0;
      if (typeof navigator !== 'undefined') {
        var _navigator = navigator,
            userAgent = _navigator.userAgent;

        browser = {
          mobile: userAgent.indexOf('Mobile') > -1,
          android: userAgent.indexOf('Android') > -1,
          ios: userAgent.indexOf('iPhone') > -1 || userAgent.indexOf('iPad') > -1
        };
      }
      var aac = urls.aac,
          mp3 = urls.rtsp,
          mobile_aac = urls.mobile_aac,
          mobile_mp3 = urls.mobile;

      // why is this an array?

      aac = aac[0];

      if (browser && browser.mobile || browser.android || browser.ios) {
        // there are mobile-specific mount points for mp3 and aac
        aac = mobile_aac ? mobile_aac : aac;
        mp3 = mobile_mp3 ? mobile_mp3 : mp3;

        // only offer aac streams on mobile for now until HLS servers are working
        // also: the mp3 mount point does not have an extension
        return [aac, { url: mp3, mimeType: 'audio/mpeg' }];
      } else {
        // the mp3 mount point does not have an extension
        return [{ url: mp3, mimeType: 'audio/mpeg' }, aac];
      }
    }
    // END-SNIPPET

  });
});