define('nypr-django-for-ember/adapters/django-page', ['exports', 'ember-data', 'fetch', 'ember-get-config', 'nypr-django-for-ember/utils/alien-dom'], function (exports, _emberData, _fetch, _emberGetConfig, _alienDom) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _emberData.default.Adapter.extend({
    findRecord: function findRecord(store, type, id /*, snapshot */) {
      // BEGIN-SNIPPET django-page-top
      if ((0, _alienDom.isInDom)(id)) {
        return document;
      }
      // END-SNIPPET

      // BEGIN-SNIPPET django-page-request

      var _id$split = id.split('?'),
          _id$split2 = _slicedToArray(_id$split, 2),
          path = _id$split2[0],
          query = _id$split2[1];

      // publisher enforces trailing slashes
      // turn "search" into "search/" and "/" into ""


      path = path === '/' ? '' : path.replace(/\/*$/, '/');
      if (query) {
        path = path + '?' + query;
      }
      return (0, _fetch.default)(_emberGetConfig.default.webRoot + '/' + path, { headers: { 'X-WNYC-EMBER': 1 } }).then(checkStatus).then(function (response) {
        return response.text();
      });
      // END-SNIPPET
    },

    // starting in ember-data 2.0, this defaults to true
    // http://emberjs.com/blog/2015/06/18/ember-data-1-13-released.html#toc_new-adapter-hooks-for-better-caching
    // ember-wormhole is the crux of our page render paradigm, and it relies on
    // element IDs which are generated by the model at run-time; reloading the model
    // in the background recomputes all the embeddedWnycComponent CPs, which causes
    // ember-wormhole to barf due to differences between the rendered IDs and the
    // model element's new IDs
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    }
  });


  function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else if (response.status === 404) {
      var error = new _emberData.default.NotFoundError();
      error.url = new URL(response.url).pathname.slice(1);
      error.response = response;
      throw error;
    } else {
      var _error = new Error(response);
      _error.response = response;
      throw _error;
    }
  }
});