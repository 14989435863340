define('nypr-django-for-ember/services/async-writer', ['exports', 'nypr-django-for-ember/utils/is-js'], function (exports, _isJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    htmlParser: Ember.inject.service(),
    scriptLoader: Ember.inject.service(),

    init: function init() {
      this._super();
      this.queue = [];
    },
    install: function install() {
      var _this = this;

      Ember.$(document).ready(function () {
        document.write = function (string) {
          return _this.write(string);
        };
      });
    },
    _nodesFrom: function _nodesFrom(string) {
      var doc = this.get('htmlParser').parse(string);
      return ['head', 'body'].map(function (part) {
        return Array.from(doc.querySelector(part).childNodes);
      }).reduce(function (a, b) {
        return a.concat(b);
      });
    },
    _extractScripts: function _extractScripts(nodes) {
      var scripts = [];
      var nonscripts = [];
      nodes.forEach(function (node) {
        if (node.tagName === 'SCRIPT' && (0, _isJs.default)(node)) {
          scripts.push(node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          Array.from(node.querySelectorAll('script')).forEach(function (script) {
            if ((0, _isJs.default)(script)) {
              scripts.push(script);
              script.parentNode.removeChild(script);
            }
          });
          nonscripts.push(node);
        }
      });
      return { scripts: scripts, nonscripts: nonscripts };
    },
    write: function write(string) {
      var _this2 = this;

      this.queue.push({ string: string, cursor: this.cursor });
      if (this.queue.length === 1) {
        setTimeout(function () {
          return _this2.flush();
        }, 0);
      }
    },
    flush: function flush() {
      var _this3 = this;

      var queue = this.queue;
      this.queue = [];

      var allScripts = [];
      queue.forEach(function (_ref) {
        var string = _ref.string,
            cursor = _ref.cursor;

        var _extractScripts2 = _this3._extractScripts(_this3._nodesFrom(string)),
            scripts = _extractScripts2.scripts,
            nonscripts = _extractScripts2.nonscripts;

        nonscripts.forEach(function (node) {
          var newNode = window.document.importNode(node, true);
          if (cursor) {
            cursor.parentElement.insertBefore(newNode, cursor);
          } else {
            document.body.appendChild(newNode);
          }
        });

        allScripts = allScripts.concat(scripts);
      });

      if (allScripts.length > 0) {
        this.get('scriptLoader').load(allScripts, document.body);
      }
    },
    cursorTo: function cursorTo(node) {
      this.cursor = node;
    }
  });
});