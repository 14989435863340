define('nypr-audio-services/services/dj', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  /* DJ knows how to play anything. Pass it a stream/story PK, or pass it a
  stream/story model and DJ will queue it up on the hifi with the appropriate
  metadata inserted */

  var STREAMS = ['wqxr', 'q2', 'wqxr-special', 'wnyc-fm939', 'wnyc-am820', 'njpr', 'jonathan-channel', 'special-events-stream', 'wqxr-special2', 'takeaway'];

  exports.default = Ember.Service.extend({
    hifi: Ember.inject.service(),
    store: Ember.inject.service(),
    actionQueue: Ember.inject.service(),
    listenAnalytics: Ember.inject.service(),

    noErrors: Ember.computed.not('hasErrors'),
    showPlayer: Ember.computed.and('noErrors', 'playedOnce'),
    playedOnce: false,

    /* So components can just depend on DJ, and not DJ + hifi (for better testing)*/
    currentSound: Ember.computed.reads('hifi.currentSound'),
    currentContentModel: Ember.computed.reads('currentSound.metadata.contentModel'),
    currentContentId: Ember.computed.reads('currentSound.metadata.contentId'),
    currentContentType: Ember.computed.reads('currentSound.metadata.contentModelType'),
    isReady: Ember.computed.reads('hifi.isReady'),
    isPlaying: Ember.computed.reads('hifi.isPlaying'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var actionQueue = Ember.get(this, 'actionQueue');
      var hifi = Ember.get(this, 'hifi');
      hifi.on('current-sound-changed', function () {
        return _this.set('playedOnce', true);
      });

      actionQueue.addAction(hifi, 'audio-ended', { priority: 1, name: 'segmented-audio' }, Ember.run.bind(this, this.playSegmentedAudio));

      this.set('currentlyLoadingIds', []); // so loading buttons can get updated when the request starts
      this.initCurrentLoadingIdsWatcher();
    },
    initCurrentLoadingIdsWatcher: function initCurrentLoadingIdsWatcher() {
      var _this2 = this;

      var hifi = Ember.get(this, 'hifi');
      hifi.on('new-load-request', function (_ref) {
        var loadPromise = _ref.loadPromise,
            options = _ref.options;

        var currentlyLoadingIds = Ember.A(Ember.get(_this2, 'currentlyLoadingIds'));
        var id = String(Ember.get(options, 'metadata.contentId'));

        if (id) {
          currentlyLoadingIds.push(id);
          Ember.set(_this2, 'currentlyLoadingIds', currentlyLoadingIds.uniq());
        }

        loadPromise.finally(function () {
          Ember.set(_this2, 'currentlyLoadingIds', currentlyLoadingIds.without(id));
        });
      });
    },
    playSegmentedAudio: function playSegmentedAudio(sound) {
      var story = Ember.get(sound, 'metadata.contentModel');

      if (story && Ember.get(story, 'segmentedAudio') && story.hasNextSegment()) {
        story.getNextSegment(); // trigger next segment
        this.play(story, { position: 0 });
        return true;
      }
    },
    fetchRecord: function fetchRecord(itemIdOrItem) {
      var modelName = this.itemModelName(itemIdOrItem);
      if (typeof itemIdOrItem === 'string') {
        return Ember.get(this, 'store').findRecord(modelName, itemIdOrItem);
      } else {
        return Ember.RSVP.Promise.resolve(itemIdOrItem);
      }
    },
    itemModelName: function itemModelName(itemIdOrItem) {
      if (typeof itemIdOrItem === 'string') {
        return STREAMS.includes(itemIdOrItem) ? 'stream' : 'story';
      } else {
        // could be a model, detect if model or stream
        return Ember.get(itemIdOrItem, 'constructor.modelName') || Ember.get(itemIdOrItem, 'modelName');
      }
    },
    itemId: function itemId(itemIdOrItem) {
      if (typeof itemIdOrItem === 'string') {
        return itemIdOrItem;
      } else {
        return itemIdOrItem.id;
      }
    },
    isNewPlay: function isNewPlay(itemIdOrItem) {
      return Ember.get(this, 'hifi.currentSound.metadata.contentId') !== this.itemId(itemIdOrItem);
    },
    play: function play(itemIdOrItem) {
      var _this3 = this;

      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var itemModelName = this.itemModelName(itemIdOrItem);
      var itemId = this.itemId(itemIdOrItem);
      var recordRequest = this.fetchRecord(itemIdOrItem);
      var newPlay = this.isNewPlay(itemIdOrItem);

      var playContext = options.playContext,
          position = options.position,
          autoPlayChoice = options.autoPlayChoice,
          _options$metadata = options.metadata,
          metadata = _options$metadata === undefined ? {} : _options$metadata;


      var audioUrlPromise = recordRequest.then(function (s) {
        // TODO: Make this consistent between models
        if (itemModelName === 'story') {
          return newPlay ? s.resetSegments() : s.getCurrentSegment();
        } else {
          return Ember.get(s, 'urls');
        }
      });

      var listenAnalytics = Ember.get(this, 'listenAnalytics');

      metadata.contentId = itemId;
      metadata.contentModelType = itemModelName;
      metadata.playContext = playContext;
      metadata.autoPlayChoice = autoPlayChoice;

      var playRequest = Ember.get(this, 'hifi').play(audioUrlPromise, { metadata: metadata, position: position });
      // This should resolve around the same time, and then set the metadata
      recordRequest.then(function (story) {
        Ember.set(metadata, 'contentModel', story);
        if (story.forListenAction) {
          story.forListenAction().then(function (data) {
            return Ember.set(metadata, 'analytics', data);
          });
        }
      });
      playRequest.then(function (_ref2) {
        var sound = _ref2.sound,
            failures = _ref2.failures;

        _this3.set('hasErrors', false);
        listenAnalytics.trackAllCodecFailures(failures, sound);
      }).catch(function (e) {
        _this3.set('hasErrors', true);
        listenAnalytics.trackSoundFailure(e);
      });

      return playRequest;
    },
    pause: function pause() {
      Ember.get(this, 'hifi').pause();
    },
    addBrowserId: function addBrowserId(id) {
      Ember.get(this, 'hifi').on('pre-load', function (urlsToTry) {
        urlsToTry.forEach(function (val, i) {
          // `val` can be a string value or an object with a `url` key
          var url = typeof val === 'string' ? val : val.url;
          var parts = url.split('?');
          if (parts.length > 2) {
            // malformed query
            var _parts = parts,
                _parts2 = _toArray(_parts),
                base = _parts2[0],
                query = _parts2.slice(1);

            parts = [base, query.join('&')];
            url = base + '?' + parts[1];
          }
          if (parts[1] && !parts[1].match('nyprBrowserId')) {
            // there's a query string that doesn't have browser_id
            url += '&nyprBrowserId=' + id;
          } else if (!parts[1]) {
            // no query
            url += '?nyprBrowserId=' + id;
          }
          if (val.url) {
            val.url = url;
          } else {
            val = url;
          }
          urlsToTry[i] = val;
        });
      });
    }
  });
});